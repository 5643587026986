<template>
  <el-dialog
    :visible="visible"
    :title="$t('columnSettings')"
    class="profiles-column-settings"
    @close="$emit('close')"
    @closed="setData"
  >
    <div class="profiles-column-settings__content">
      <div class="profiles-column-settings__group">
        <b class="profiles-column-settings__group__title">{{ $t('groups') }}</b>
        <el-checkbox
          :value="checkedGroups.length === groups.length"
          :indeterminate="checkedGroups.length > 0 && checkedGroups.length !== groups.length"
          class="profiles-column-settings__check-all"
          @change="handleCheckAllGroups"
        >
          {{ $t('checkAll') }}
        </el-checkbox>
        <div class="profiles-column-settings__group__list">
          <draggable
            v-model="groups"
            :animation="200"
            drag-class="profiles-column-settings__group__item__drag"
            @start="dragging = true"
            @end="dragging = false"
          >
            <transition-group type="transition" :name="!dragging ? 'flip' : ''">
              <div
                v-for="(group, index) in groups"
                :key="group"
                class="profiles-column-settings__group__item"
              >
                <icon
                  name="drag-row"
                  :class="[
                    'profiles-column-settings__group__item__drag',
                    { 'profiles-column-settings__group__item__drag--active': dragging },
                  ]"
                />
                <el-checkbox
                  :key="group"
                  :label="group"
                  :value="checkedGroups.includes(group)"
                  :indeterminate="
                    checkedGroupColumns[index].length > 0 && !checkedGroups.includes(group)
                  "
                  @change="handleGroupCheckedChange(group, $event)"
                >
                  <span @click.prevent="activeGroup = group">{{
                    $t(`data.attributeGroups.${group}`)
                  }}</span>
                </el-checkbox>
                <icon
                  v-show="activeGroup === group"
                  class="profiles-column-settings__group__item__arrow"
                  name="arrow-right"
                />
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div class="profiles-column-settings__group">
        <b class="profiles-column-settings__group__title">{{ $t('columns') }}</b>
        <el-input
          v-model="groupColumnsQuery"
          :placeholder="$t('searchColumn')"
          class="profiles-column-settings__search"
          size="small"
        >
          <icon slot="prefix" name="search" width="16" height="16" />
        </el-input>
        <div class="profiles-column-settings__group__list">
          <draggable
            v-model="groupColumns[activeGroup]"
            :animation="200"
            drag-class="profiles-column-settings__group__item__drag"
            @start="dragging = true"
            @end="dragging = false"
          >
            <transition-group type="transition" :name="!dragging ? 'flip' : ''">
              <div
                v-for="(column, index) in filteredGroupColumns"
                :key="column.id"
                class="profiles-column-settings__group__item"
              >
                <icon
                  name="drag-row"
                  :class="[
                    'profiles-column-settings__group__item__drag',
                    { 'profiles-column-settings__group__item__drag--active': dragging },
                  ]"
                />
                <el-checkbox v-model="groupColumns[activeGroup][index].visible" :label="column.id">
                  {{ column.name }}
                </el-checkbox>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <dialog-footer
      slot="footer"
      :submit-label="$t('actions.apply')"
      :disabled="submitDisabled"
      @cancel="$emit('close')"
      @submit="handleApply"
    />
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'ProfilesColumnSettings',

  components: {
    draggable,
    DialogFooter,
  },

  props: {
    visible: Boolean,
  },

  data() {
    return {
      groups: [],
      activeGroup: 'user',
      groupColumns: {},
      groupColumnsQuery: '',

      dragging: false,
    }
  },

  computed: {
    ...mapGetters('data', [
      'activeAttributes',
      'activeAttributeGroups',
      'activeWorkspaceAttributes',
    ]),
    checkedGroups() {
      return this.groups.filter(
        (group, index) => this.checkedGroupColumns[index].length === this.groupColumns[group].length
      )
    },
    checkedGroupColumns() {
      return this.groups.map(group => this.groupColumns[group].filter(column => column.visible))
    },
    filteredGroupColumns() {
      const activeAttributes = this.groupColumns[this.activeGroup]
        ? this.groupColumns[this.activeGroup].map(column => this.activeAttributes[column.id])
        : []
      if (this.groupColumnsQuery) {
        return activeAttributes.filter(attribute =>
          attribute.name
            .toString()
            .toLowerCase()
            .includes(this.groupColumnsQuery.toLowerCase())
        )
      }
      return activeAttributes
    },
    submitDisabled() {
      return this.activeWorkspaceAttributes.every(
        (group, groupIndex) =>
          group.type === this.groups[groupIndex] &&
          group.visible === this.checkedGroupColumns[groupIndex].length > 0 &&
          this.groupColumns[group.type].every(
            (column, columnIndex) =>
              column.id === group.list[columnIndex].id &&
              column.visible === group.list[columnIndex].visible
          )
      )
    },
  },

  watch: {
    activeGroup() {
      this.groupColumnsQuery = ''
    },
  },

  mounted() {
    this.setData()
  },

  methods: {
    ...mapActions('data', ['editWorkspaceAttributes']), // change to profileAttributes
    setData() {
      this.groupColumnsQuery = ''
      this.groups = []
      this.groupColumns = {}
      this.dragging = false

      this.activeWorkspaceAttributes.forEach(group => {
        this.groups.push(group.type)
        this.$set(this.groupColumns, group.type, JSON.parse(JSON.stringify(group.list)))
      })
      this.activeGroup = this.groups[0]
    },
    handleCheckAllGroups(val) {
      this.groups.forEach(group => {
        this.handleGroupCheckedChange(group, val)
      })
    },
    handleGroupCheckedChange(group, val) {
      this.groupColumns[group].forEach(column => {
        column.visible = val
      })
    },
    handleApply() {
      this.editWorkspaceAttributes(
        this.groups.map((group, index) => ({
          type: group,
          visible: this.checkedGroupColumns[index].length > 0,
          list: this.groupColumns[group],
        }))
      )
      this.$emit('close')
    },
  },
}
</script>

<i18n>
{
  "en": {
    "columnSettings": "Column settings",
    "groups": "Groups",
    "columns": "Columns",
    "checkAll": "Check all",
    "searchColumn": "Search column"
  },
  "ru": {
    "columnSettings": "Настройки колонок",
    "groups": "Группы",
    "columns": "Колонки",
    "checkAll": "Выбрать все",
    "searchColumn": "Найти колонку"
  }
}
</i18n>

<style lang="scss">
.profiles-column-settings {
  .el-dialog {
    max-width: 500px;
  }
}
.profiles-column-settings__content {
  display: flex;
  justify-content: space-between;
}
.profiles-column-settings__check-all {
  margin-bottom: $spacing-s;
  padding: 0 $spacing-xs;
}
.profiles-column-settings__group {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
}
.profiles-column-settings__group__list {
  display: flex;
  flex-direction: column;
}
.profiles-column-settings__group__item {
  display: flex;
  cursor: pointer;
  list-style: none;
  padding: $spacing-xs;
  padding-left: $spacing-2xs;
  background-color: $color-layout-light-01;
  border-radius: 4px;
  &.el-checkbox {
    margin-right: 0;
  }
  svg {
    opacity: 0.3;
  }
  .el-checkbox__label {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
  }
}
.profiles-computed {
  .el-select-dropdown__item {
    height: auto;
  }
}
.profiles-column-settings__group__title {
  margin-bottom: $spacing-s;
}
.profiles-column-settings__search {
  margin-bottom: $spacing-xs;
}
.profiles-column-settings__item--active {
  position: relative;
  background-color: $color-layout-light-03;
  color: $color-primary;
  font-weight: 500;
}
.profiles-column-settings__group__item__arrow {
  margin-left: auto;
}
.profiles-column-settings__item--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.profiles-column-settings__group__item__drag {
  cursor: grab;
  &--active {
    cursor: grabbing;
  }
}
</style>
