<template>
  <div class="exports">
    <data-table
      :title="$t('exports')"
      :data="tableData"
      :data-columns="tableColumns"
      :empty-text="$t('noExportsAdded')"
      :not-found-text="$t('noExportsFound')"
    >
      <template slot="action-tools">
        <info-select :label="$t('addExport')" is-action :options="exportOptions" />
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '@/components/DataTable'
import InfoSelect from '@/components/InfoSelect'

export default {
  name: 'Exports',

  components: {
    DataTable,
    InfoSelect,
  },

  data() {
    return {
      tableColumns: ['name', 'type', 'segmentNames', 'exportTo', 'updated', 'status'],
      exports: [
        { name: 'myTarget', icon: 'application' },
        { name: 'yandex', icon: 'application' },
        { name: 'google', icon: 'application' },
        { name: 'facebook', icon: 'application' },
        { name: 'api', icon: 'cog' },
        { name: 'hybridDsp', icon: 'server' },
      ],
    }
  },

  computed: {
    ...mapGetters('data', ['activeWorkspaceExports']),
    tableData() {
      return this.activeWorkspaceExports.map(item => {
        item.type = this.$i18n.t(item.type)
        return item
      })
    },
    exportOptions() {
      return this.exports.map(({ name, icon }) => ({
        name,
        icon,
        label: this.$i18n.t(`exports.${name}.label`),
        description: this.$i18n.t(`exports.${name}.description`),
      }))
    },
  },
}
</script>

<i18n>
{
  "en": {
    "exports": "Exports",
    "noExportsAdded": "This workspace doesn't contain any exports yet",
    "noExportsFound": "No matching exports found",
    "once": "Once",
    "periodic": "Periodic",
    "addExport": "Add export",
    "exports": {
      "myTarget": {
        "label": "myTarget",
        "description": "myTarget export description"
      },
      "yandex": {
        "label": "Yandex",
        "description": "Yandex export description"
      },
      "google": {
        "label": "Google",
        "description": "Google export description"
      },
      "facebook": {
        "label": "Facebook",
        "description": "Facebook export description"
      },
      "api": {
        "label": "API",
        "description": "API export description"
      },
      "hybridDsp": {
        "label": "Hybrid DSP",
        "description": "Hybrid DSP export description"
      }
    }
  },
  "ru": {
    "exports": "Экспорты",
    "noExportsAdded": "В этой рабочей области пока нет сегментов",
    "noExportsFound": "Сегменты, соответствующие вашему запросу, не найдены",
    "once": "Единоразовый",
    "periodic": "Периодический",
    "addExport": "Добавить экспорт",
    "exports": {
      "myTarget": {
        "label": "myTarget",
        "description": ""
      },
      "yandex": {
        "label": "Yandex",
        "description": ""
      },
      "google": {
        "label": "Google",
        "description": ""
      },
      "facebook": {
        "label": "Facebook",
        "description": ""
      },
      "api": {
        "label": "API",
        "description": ""
      },
      "hybridDsp": {
        "label": "Hybrid DSP",
        "description": ""
      }
    }
  }
}
</i18n>

<style lang="scss">
.exports {
  height: auto;
}
</style>
