<template>
  <el-dialog
    class="profiles-item-edit"
    :visible="visible"
    :title="$t('title', { attribute: attribute.name })"
    append-to-body
    @close="$emit('close')"
  >
    <el-form ref="form" label-position="top" label-width="100px" size="small">
      <el-form-item :label="$t('label')">
        <el-input v-model="value" />
      </el-form-item>
      <el-form-item :label="$t('history')">
        <el-timeline class="profiles-item-edit__timeline">
          <el-timeline-item
            v-for="item in history"
            :key="item.dt"
            :timestamp="item.dtLabel"
            :color="item.value === value ? '#3c4ca9' : ''"
            @click.native="value = item.value"
          >
            {{ item.value }}
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
    </el-form>
    <dialog-footer
      slot="footer"
      :loading="submitLoading"
      :disabled="submitDisabled || value === attribute.value"
      @cancel="$emit('close')"
      @submit="handleSubmit"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'ProfilesItemEdit',

  components: {
    DialogFooter,
  },

  props: {
    visible: Boolean,
    attribute: Object,
    profileId: String,
  },

  data() {
    return {
      value: '',

      submitDisabled: false,
      submitLoading: false,
    }
  },

  computed: {
    ...mapState('data', ['profileAttributesHistory']),
    attributesHistory() {
      return this.profileAttributesHistory[this.profileId]
    },
    history() {
      return this.attributesHistory && this.attribute.id
        ? this.attributesHistory[this.attribute.id]
        : []
    },
  },

  watch: {
    visible(val) {
      if (val) {
        this.value = this.attribute.value
        this.getProfileAttributeHistory({
          attributeId: this.attribute.id,
          profileId: this.profileId,
        })
      }
    },
  },

  methods: {
    ...mapActions('data', ['getProfileAttributeHistory', 'changeProfileAttribute']),
    async handleSubmit() {
      this.submitLoading = true
      const { error } = await this.changeProfileAttribute({
        profileId: this.profileId,
        attributeId: this.attribute.id,
        value: this.value,
      })
      this.submitLoading = false

      if (error) {
        this.submitDisabled = true
        return false
      } else {
        this.$emit('close')
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Attribute \"{attribute}\"",
    "label": "Attribute",
    "history": "History"
  },
  "ru": {
    "title": "Атрибут \"{attribute}\"",
    "label": "Атрибут",
    "history": "История"
  }
}
</i18n>

<style lang="scss">
.profiles-item-edit__timeline {
  margin-top: $spacing-xs;
}
</style>
