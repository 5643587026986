<template>
  <div class="workspaces">
    <div class="workspaces__content">
      <div>
        <div class="workspaces__header">
          <h6 class="workspaces__title">{{ $t('recent') }}</h6>
          <div class="workspaces__header__action">
            <info-select
              :label="$t('addWorkspace')"
              show-groups
              is-action
              :options="workspacesOptions"
              @change="handleAddWorkspace"
            />
            <el-input v-model="searchQuery" size="small" class="data-table__header__search">
              <icon slot="prefix" height="16" width="16" name="search" />
            </el-input>
          </div>
        </div>
        <div class="workspaces__container">
          <el-card
            v-for="workspace in workspaces"
            :key="workspace.id"
            shadow="never"
            class="workspaces__card"
            @mouseenter.native="hoveredWorkspaceId = workspace.id"
            @mouseleave.native="hoveredWorkspaceId = ''"
            @click.native="$router.push(`/workspace/${workspace.id}`)"
          >
            <h6>{{ workspace.name }}</h6>
            <p>{{ workspace.description }}</p>
            <span class="workspace__time">{{ $t('visited') }}: {{ workspace.visitedLabel }}</span>
            <el-button
              v-show="workspace.pinned || hoveredWorkspaceId === workspace.id"
              type="text"
              :class="['workspace__pin', { 'workspace__pin--active': workspace.pinned }]"
              @click="toggleWorkspacePinned(workspace)"
            >
              <icon height="16" width="16" name="pin" />
            </el-button>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InfoSelect from '@/components/InfoSelect'

export default {
  name: 'Workspaces',

  components: {
    InfoSelect,
  },

  metaInfo() {
    return {
      title: this.$i18n.t('title'),
    }
  },

  data() {
    return {
      searchQuery: '',
      types: [
        { name: 'custom', options: ['default', 'blank'] },
        { name: 'webActivity', options: ['newsletter'] },
      ],
      hoveredWorkspaceId: '',
    }
  },

  computed: {
    ...mapGetters('data', ['activeWorkspacesList']),
    pinnedWorkspaces() {
      return this.workspaces.filter(workspace => workspace.pinned)
    },
    workspaces() {
      const searchFields = ['name', 'description', 'visitedLabel']
      const workspaces = this.activeWorkspacesList.slice().sort((a, b) => {
        if (a.pinned !== b.pinned) {
          return a.pinned ? -1 : 1
        }
        return 1
      })

      if (this.searchQuery) {
        return workspaces.filter(workspace =>
          searchFields.some(field =>
            workspace[field]
              .toString()
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
          )
        )
      }
      return workspaces
    },
    workspacesOptions() {
      return this.types.map(type => ({
        name: type.name,
        label: this.$i18n.t(`workspacesOptions.${type.name}.label`),
        options: type.options.map(option => ({
          name: option,
          label: this.$i18n.t(`workspacesOptions.${type.name}.options.${option}.label`),
          description: this.$i18n.t(`workspacesOptions.${type.name}.options.${option}.description`),
          example: this.$i18n.t(`workspacesOptions.${type.name}.options.${option}.example`),
          link: '#',
        })),
      }))
    },
  },

  methods: {
    ...mapActions('data', ['createCloudWorkspace', 'editCloudWorkspace']),
    async handleAddWorkspace(val) {
      const { error, id } = await this.createCloudWorkspace({
        type: val.name,
        name: val.label,
        description: val.description,
      })

      if (!error) {
        this.$router.push(`/workspace/${id}`)
      }
    },
    toggleWorkspacePinned(workspace) {
      this.editCloudWorkspace({
        id: workspace.id,
        data: { pinned: !workspace.pinned },
      })
      this.hoveredWorkspaceId = ''
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Workspaces",
    "workspaces": {
      "title": "Workspaces",
      "description": "Workspace — page, where you can manage all necessary tools for your tasks: from data organization to statistics and analytics"
    },
    "pinned": "Pinned",
    "recent": "Recent",
    "visited": "Visited",
    "addWorkspace": "Add workspace",
    "workspacesOptions": {
      "custom": {
        "label": "Custom",
        "options": {
          "default": {
            "label": "Default",
            "description": "Basic workspace with default data tools: profiles, segments and export",
            "example": "Check profiles, build segment and export to integrated system"
          },
          "blank": {
            "label": "Blank",
            "description": "Blank page",
            "example": "-"
          }
        }
      },
      "webActivity": {
        "label": "Web activity",
        "options": {
          "newsletter": {
            "label": "User newsletter",
            "description": "Send newsletter to users that performs a certain behavior",
            "example": "If user adds product to cart, but doesn't buy it, send letter to his email to continue order"
          }
        }
      }
    }
  },
  "ru": {
    "title": "Рабочие области",
    "workspaces": {
      "title": "Рабочие области",
      "description": "Рабочая область — страница, на которой вы можете управлять всеми необходимыми инструментами для решения ваших задач: от организации данных до статистики и аналитики"
    },
    "pinned": "Закрепленные",
    "recent": "Недавние",
    "visited": "Активность",
    "addWorkspace": "Добавить рабочую область",
    "workspacesOptions": {
      "custom": {
        "label": "Пользовательские",
        "options": {
          "default": {
            "label": "Стандартное",
            "description": "Страница с базовыми инструментами управления данными: профили, сегменты, экспорт",
            "example": "Просмотреть профили, собрать сегмент, выгрузить в интегрированную систему"
          },
          "blank": {
            "label": "Пустое",
            "description": "",
            "example": "-"
          }
        }
      },
      "webActivity": {
        "label": "Активность в интернете",
        "options": {
          "newsletter": {
            "label": "Пользовательская рассылка",
            "description": "Отправить письмо пользователям, которые совершили определенное действие",
            "example": "Если пользователь добавил товар в корзину, но не совершил покупку, отправить ему письмо с приглашением совершить покупку"
          }
        }
      }
    }
  }
}
</i18n>

<style lang="scss">
.workspaces__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-m;
}
.workspaces__title {
  margin-bottom: 0;
}
.workspaces__description {
  margin-bottom: $spacing-2xs;
}
.workspaces__header__action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $spacing-xs;
}
.data-table__header__search {
  max-width: 200px;
  margin-left: $spacing-m;
}
.workspaces__content {
  padding: $spacing-l;
}
.workspaces__container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $spacing-m;
  > * {
    margin-right: $spacing-m;
  }
  > *:last-child {
    margin-right: 0;
  }
}
.workspaces__card {
  position: relative;
  width: 250px;
  height: 200px;
  cursor: pointer;
  border: 2px solid $color-layout-light-02;
  margin-bottom: $spacing-s;
  &:hover {
    border: 2px solid #acb3da;
  }
}
.workspace__time {
  position: absolute;
  bottom: $spacing-m;
  font-size: 12px;
  opacity: 0.7;
}
.workspace__pin {
  position: absolute;
  bottom: $spacing-m;
  right: $spacing-m;
  opacity: 0.4;
  padding: 0;
  height: auto;
  &:hover {
    opacity: 1;
  }
  &--active {
    opacity: 1;
  }
}
</style>
