<template>
  <div class="profiles">
    <data-table
      ref="table"
      :title="$t('profiles')"
      :data="tableData"
      :data-columns="tableColumns"
      :loading="loading"
      :empty-text="$t('noProfilesAdded')"
      :not-found-text="$t('noProfilesFound')"
      selection="single"
      no-column-labels
      @refresh="getCloudProfiles"
      @selection-change="handleSelectionChange"
    >
      <template slot="tools">
        <el-button class="el-button_icon--prefix" size="small" @click="showColumnSettings">
          <icon height="14" width="14" name="column" />{{ $t('columns') }}
        </el-button>
      </template>
    </data-table>

    <component
      :is="dialog"
      :id="selectedProfileId"
      :visible="showDialog"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '@/components/DataTable'
import ProfilesItem from './ProfilesItem'
import ProfilesColumnSettings from './ProfilesColumnSettings'

export default {
  name: 'Profiles',

  components: {
    item: ProfilesItem,
    columnSettings: ProfilesColumnSettings,
    DataTable,
  },

  data() {
    return {
      dialog: '',
      showDialog: false,
      selectedProfileId: '',

      loading: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeProfilesList', 'activeWorkspaceAttributesList']),
    tableData() {
      if (!this.activeProfilesList) {
        return []
      }

      return this.activeProfilesList.map(profile => {
        const flatData = { id: profile.id }
        const flatternField = val => {
          flatData[val.name] = val.value
        }
        profile.computed.forEach(flatternField)
        profile.user.forEach(flatternField)
        return flatData
      })
    },
    tableColumns() {
      return this.activeWorkspaceAttributesList.map(attribute => attribute.name)
    },
  },

  methods: {
    ...mapActions('data', ['getCloudProfiles']),
    handleSelectionChange(event) {
      this.selectedProfileId = event.id
      this.dialog = 'item'
      this.showDialog = true
    },
    showColumnSettings() {
      this.dialog = 'columnSettings'
      this.showDialog = true
    },
  },
}
</script>

<i18n>
{
  "en": {
    "profiles": "Profiles",
    "noProfilesAdded": "This cloud doesn't contain any profiles yet",
    "noProfilesFound": "No matching profiles found",
    "columns": "Columns"
  },
  "ru": {
    "profiles": "Профили",
    "noProfilesAdded": "В этом облаке пока нет профилей",
    "noProfilesFound": "Профили, соответствующие вашему запросу, не найдены",
    "columns": "Колонки"
  }
}
</i18n>

<style lang="scss">
.profiles {
  height: auto;
}
</style>
