<template>
  <div class="segments">
    <data-table
      :title="$t('segments')"
      :data="activeWorkspaceSegments"
      :data-columns="tableColumns"
      :empty-text="$t('noSegmentsAdded')"
      :not-found-text="$t('noSegmentsFound')"
    >
      <template slot="action-tools">
        <el-button
          class="el-button_icon--prefix"
          size="small"
          type="primary"
          @click="showBuilder = true"
        >
          <icon height="14" width="14" name="add-circle" />
          {{ $t('addSegment') }}
        </el-button>
      </template>
    </data-table>

    <segments-builder :visible="showBuilder" @close="showBuilder = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '@/components/DataTable'
import SegmentsBuilder from './SegmentsBuilder'

export default {
  name: 'Segments',

  components: {
    DataTable,
    SegmentsBuilder,
  },

  data() {
    return {
      tableColumns: ['name', 'profiles', 'description', 'updated', 'status'],
      showBuilder: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeWorkspaceSegments']),
  },
}
</script>

<i18n>
{
  "en": {
    "segments": "Segments",
    "noSegmentsAdded": "This workspace doesn't contain any segments yet",
    "noSegmentsFound": "No matching segments found",
    "addSegment": "Add segment"
  },
  "ru": {
    "segments": "Сегменты",
    "noSegmentsAdded": "В этой рабочей области пока нет сегментов",
    "noSegmentsFound": "Сегменты, соответствующие вашему запросу, не найдены",
    "addSegment": "Добавить сегмент"
  }
}
</i18n>

<style lang="scss">
.segments {
  height: auto;
}
</style>
