<template>
  <div class="workspace">
    <div class="workspace__header">
      <p class="workspace__description">{{ activeWorkspace.description }}</p>
      <div class="workspace__header__action">
        <div>
          <el-button
            size="small"
            class="el-button_icon--prefix"
            @click="
              showDialog = true
              dialogType = 'edit'
            "
          >
            <icon width="14" height="14" name="edit" />{{ $t('actions.edit') }}
          </el-button>
          <el-button
            size="small"
            class="el-button_icon--prefix"
            @click="
              showDialog = true
              dialogType = 'duplicate'
            "
          >
            <icon width="14" height="14" name="copy" />{{ $t('actions.duplicate') }}
          </el-button>
          <el-button
            size="small"
            class="el-button_icon--prefix"
            :type="isManageMode ? 'primary' : 'default'"
            @click.stop="toggleManageMode"
          >
            <icon width="14" height="14" name="click-target" />{{ $t('actions.manage') }}
          </el-button>
        </div>
        <div>
          <el-button
            size="small"
            class="el-button_icon--prefix"
            @click="getCloudWorkspaces(activeCloudId)"
          >
            <icon width="14" height="14" name="history" />{{ $t('actions.refresh') }}
          </el-button>
          <el-button size="small" class="el-button_icon--prefix" disabled>
            <icon width="14" height="14" name="export" />{{ $t('actions.export') }}
          </el-button>
          <info-select
            :label="$t('addItem')"
            show-groups
            is-action
            class="workspace__header__addItem"
            :options="itemOptions"
            @change="handleAddItem"
          />
        </div>
      </div>
    </div>

    <div class="workspace__content">
      <template v-if="!items || !items.length">
        <div slot="empty" class="workspace__empty">
          <div class="workspace__empty__img"></div>
          <div class="workspace__empty__content">
            <p>{{ $t('noItemsAdded') }}</p>
          </div>
        </div>
      </template>
      <template v-else>
        <draggable
          v-model="managedItems"
          :disabled="!isManageMode"
          @start="draggedItem = managedItems[$event.oldIndex]"
          @end="draggedItem = ''"
        >
          <transition-group
            type="transition"
            :name="isManageMode && !draggedItem ? 'workspace__items--flip' : ''"
            appear
          >
            <div
              v-for="(item, index) in items"
              :key="item.type"
              :class="[
                'workspace__item',
                {
                  'workspace__item--dragged': isManageMode && draggedItem === item.type,
                  'workspace__item--managed': isManageMode,
                },
              ]"
            >
              <el-button
                v-if="isManageMode"
                type="text"
                size="small"
                class="workspace__item__icon-close"
                @click.stop="handleDeleteItem(index)"
              >
                <icon width="16" height="16" name="close" />
              </el-button>
              <icon
                v-if="isManageMode"
                width="24"
                height="24"
                name="drag-grid"
                class="workspace__item__icon-drag"
              />
              <el-card class="workspace__item__card" shadow="never">
                <div slot="header" class="workspace__item__card__header">
                  <h6>
                    {{ $t(`items.${item.group}.options.${item.type}.label`) }}
                  </h6>
                  <el-button type="text">
                    <icon height="16" width="16" name="dots-vertical" />
                  </el-button>
                </div>
                <keep-alive>
                  <component :is="item.type" v-bind="{ ...item }" :class="item.type" />
                </keep-alive>
              </el-card>
            </div>
          </transition-group>
        </draggable>
      </template>
    </div>

    <edit-dialog
      :visible="showDialog"
      :title="$t(dialogType === 'edit' ? 'editWorkspace' : 'duplicateWorkspace')"
      :allow-same-data="dialogType === 'duplicate'"
      :data="editData"
      :error="submitError"
      @clearError="submitError = false"
      @close="showDialog = false"
      @submit="handleDialogSubmit"
    >
      <template v-if="dialogType === 'duplicate'" v-slot:items="{ formdata }">
        <el-checkbox-group v-model="formdata.items" class="duplicate__item-list">
          <el-checkbox v-for="item in activeWorkspace.items" :key="item.type" :label="item.type">
            {{ $t(`items.${item.group}.options.${item.type}.label`) }}
          </el-checkbox>
        </el-checkbox-group>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import EditDialog from '@/components/EditDialog'
import InfoSelect from '@/components/InfoSelect'

import BarChart from '@/components/charts/BarChart'
import BubbleChart from '@/components/charts/BubbleChart'
import DoughnutChart from '@/components/charts/DoughnutChart'
import LineChart from '@/components/charts/LineChart'
import RadarChart from '@/components/charts/RadarChart'

import Profiles from './components/profiles/Profiles'
import Segments from './components/segments/Segments'
import Exports from './components/Exports'

export default {
  name: 'Workspace',

  metaInfo() {
    return {
      title: this.$i18n.t('title'),
    }
  },

  components: {
    EditDialog,
    InfoSelect,
    barChart: BarChart,
    bubbleChart: BubbleChart,
    doughnutChart: DoughnutChart,
    lineChart: LineChart,
    radarChart: RadarChart,
    profiles: Profiles,
    segments: Segments,
    exports: Exports,
    draggable,
  },

  data() {
    return {
      showDialog: false,
      dialogType: 'edit',
      isManageMode: false,
      managedItems: [],
      draggedItem: '',

      submitError: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeWorkspace', 'activeAttributeGroups']),
    itemOptions() {
      return [
        {
          name: 'data',
          label: this.$i18n.t('items.data.label'),
          options: [
            {
              name: 'profiles',
              label: this.$i18n.t('items.data.options.profiles.label'),
              description: this.$i18n.t('items.data.options.profiles.description'),
              disabled: this.activeWorkspace.profiles,
            },
            {
              name: 'segments',
              label: this.$i18n.t('items.data.options.segments.label'),
              description: this.$i18n.t('items.data.options.segments.description'),
              disabled: this.activeWorkspace.segments,
            },
            {
              name: 'exports',
              label: this.$i18n.t('items.data.options.exports.label'),
              description: this.$i18n.t('items.data.options.exports.description'),
              disabled: this.activeWorkspace.exports,
            },
          ],
        },
        {
          name: 'statistics',
          label: this.$i18n.t('items.statistics.label'),
          options: [
            {
              name: 'lineChart',
              label: this.$i18n.t('items.statistics.options.lineChart.label'),
              description: this.$i18n.t('items.statistics.options.lineChart.description'),
            },
            {
              name: 'barChart',
              label: this.$i18n.t('items.statistics.options.barChart.label'),
              description: this.$i18n.t('items.statistics.options.barChart.description'),
            },
            {
              name: 'radarChart',
              label: this.$i18n.t('items.statistics.options.radarChart.label'),
              description: this.$i18n.t('items.statistics.options.radarChart.description'),
            },
            {
              name: 'doughnutChart',
              label: this.$i18n.t('items.statistics.options.doughnutChart.label'),
              description: this.$i18n.t('items.statistics.options.doughnutChart.description'),
            },
            {
              name: 'bubbleChart',
              label: this.$i18n.t('items.statistics.options.bubbleChart.label'),
              description: this.$i18n.t('items.statistics.options.bubbleChart.description'),
              disabled: true,
            },
          ],
        },
      ]
    },
    itemTemplates() {
      return {
        data: {
          profiles: {
            type: 'profiles',
            group: 'data',
            filters: [],
            sorts: [],
            attributes: Object.keys(this.activeAttributeGroups).map(group => ({
              type: group,
              visible: true,
              list: this.activeAttributeGroups[group].map(id => ({
                id,
                visible: true,
              })),
            })),
          },
          segments: {
            type: 'segments',
            group: 'data',
            filters: [],
            sorts: [],
            ids: [],
          },
          exports: {
            type: 'exports',
            group: 'data',
            filters: [],
            sorts: [],
            ids: [],
          },
        },
        statistics: {
          // test samples
          lineChart: {
            type: 'lineChart',
            group: 'statistics',
            data: {
              datasets: [
                {
                  label: 'Dataset 1',
                  data: [10, 20, 10, 4, 30],
                  fill: false,
                  borderColor: '#3c4ca9',
                },
                {
                  label: 'Dataset 2',
                  data: [20, 10, 3, 10, 15],
                  fill: false,
                  borderColor: '#3c4ca9',
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
            },
          },
          barChart: {
            type: 'barChart',
            group: 'statistics',
            data: {
              labels: ['January', 'February', 'March', 'April', 'May'],
              datasets: [
                {
                  label: 'Dataset 1',
                  data: [10, 20, 10, 4, 30],
                  borderColor: '#3c4ca9',
                },
                {
                  label: 'Dataset 2',
                  data: [20, 10, 3, 10, 15],
                  borderColor: '#3c4ca9',
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
            },
          },
          radarChart: {
            type: 'radarChart',
            group: 'statistics',
            data: {
              labels: [['Eating', 'Dinner'], ['Drinking', 'Water'], 'Sleeping'],
              datasets: [
                {
                  label: 'Dataset 1',
                  data: [10, 20, 10, 4, 30],
                  fill: false,
                  borderColor: '#3c4ca9',
                },
                {
                  label: 'Dataset 2',
                  data: [20, 10, 3, 10, 15],
                  fill: false,
                  borderColor: '#3c4ca9',
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
            },
          },
          doughnutChart: {
            type: 'doughnutChart',
            group: 'statistics',
            data: {
              datasets: [{ data: [10, 20, 10, 4, 30] }],
              labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
            },
          },
          bubbleChart: { type: 'bubbleChart', group: 'statistics', data: {} },
        },
      }
    },
    items() {
      if (this.isManageMode && this.managedItems.length) {
        return this.managedItems.map(type => this.activeWorkspace[type])
      } else {
        return this.activeWorkspace.items
      }
    },
    editData() {
      const data = [
        {
          name: 'name',
          value: this.activeWorkspace.name,
          label: this.$i18n.t('name.label'),
          message: this.$i18n.t('name.required'),
        },
        {
          name: 'description',
          value: this.activeWorkspace.description,
          label: this.$i18n.t('description'),
          type: 'textarea',
        },
      ]
      if (this.dialogType === 'duplicate' && this.activeWorkspace.items.length) {
        data.push({
          name: 'items',
          value: this.activeWorkspaceItemTypes,
          label: this.$i18n.t('items.label'),
          custom: true,
        })
      }
      return data
    },
    activeWorkspaceItemTypes() {
      return this.activeWorkspace.items.length
        ? this.activeWorkspace.items.map(item => item.type)
        : []
    },
  },

  beforeCreate() {
    if (!this.$store.getters['data/activeWorkspaces'][this.$route.params.id]) {
      this.$router.push('/workspaces')
    } else {
      this.$store.commit('data/setActiveWorkspaceId', this.$route.params.id)
    }
  },

  created() {
    document.body.addEventListener('click', this.handleBodyClick)
  },

  beforeDestroy() {
    document.body.removeEventListener('click', this.handleBodyClick)
  },

  beforeRouteUpdate(to, from, next) {
    this.isManageMode = false
    this.$store.commit('data/setActiveWorkspaceId', to.params.id)
    next()
  },

  methods: {
    ...mapActions('data', [
      'getCloudWorkspaces',
      'editCloudWorkspace',
      'addCloudWorkspaceItem',
      'duplicateCloudWorkspace',
    ]),
    toggleManageMode(isManage) {
      const val = isManage || !this.isManageMode
      this.isManageMode = val

      if (val) {
        this.managedItems = this.activeWorkspaceItemTypes
      } else {
        this.editCloudWorkspace({
          id: this.activeWorkspace.id,
          data: {
            items: this.managedItems.map(item => this.activeWorkspace[item]),
          },
        })
      }
    },
    handleDialogSubmit(data) {
      if (this.dialogType === 'edit') {
        return this.handleEditWorkspace(data)
      } else {
        return this.handleDuplicateWorkspace(data)
      }
    },
    async handleEditWorkspace(data) {
      const { error } = await this.editCloudWorkspace({
        id: this.$route.params.id,
        data,
      })

      if (error) {
        this.submitError = true
      } else {
        this.showDialog = false
      }
    },
    async handleDuplicateWorkspace(data) {
      const workspace = {
        ...data,
        items: data.items.map(item => this.activeWorkspace[item]),
      }
      const { id } = await this.duplicateCloudWorkspace(workspace)
      this.showDialog = false
      if (id) {
        this.$router.push(`/workspace/${id}`)
      }
    },
    async handleAddItem(item, group) {
      await this.addCloudWorkspaceItem(this.itemTemplates[group.name][item.name])
    },
    handleDeleteItem(index) {
      this.managedItems.splice(index, 1)
      if (!this.managedItems.lenght) {
        this.toggleManageMode(false)
      }
    },
    handleBodyClick() {
      if (this.isManageMode && !this.draggedItem) {
        this.toggleManageMode(false)
      }
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "Workspace",
    "addItem": "Add item",
    "editWorkspace": "Edit workspace",
    "duplicateWorkspace": "Duplicate workspace",
    "name": {
      "label": "Name",
      "required": "Please enter workspace name"
    },
    "noItemsAdded": "You have not added any items to your workspace",
    "items": {
      "label": "Items",
      "data": {
        "label": "Data",
        "options": {
          "profiles": {
            "label": "Profiles",
            "description": "Profiles shows information separately on each customer profile with ability to change data and watch history of changes."
          },
          "segments": {
            "label": "Segments",
            "description": "Segments allows to add and manage customer segmentation based on events received from added data sources and specified attribute conditions."
          },
          "exports": {
            "label": "Exports",
            "description": "Exports allows to add periodic and one-time exports to target destinations."
          }
        }
      },
      "statistics": {
        "label": "Statistics",
        "options": {
          "lineChart": {
            "label": "Line chart",
            "description": "Chart description"
          },
          "barChart": {
            "label": "Bar chart",
            "description": "Chart description"
          },
          "radarChart": {
            "label": "Radar chart",
            "description": "Chart description"
          },
          "doughnutChart": {
            "label": "Doughnut chart",
            "description": "Chart description"
          },
          "bubbleChart": {
            "label": "Bubble chart",
            "description": "Chart description"
          }
        }
      }
    }
  },
  "ru": {
    "title": "Рабочая область",
    "addItem": "Добавить элемент",
    "editWorkspace": "Редактировать рабочую область",
    "duplicateWorkspace": "Дублировать рабочую область",
    "name": {
      "label": "Название",
      "required": "Пожалуйста, введите название рабочей области"
    },
    "noItemsAdded": "Вы пока не добавили элементы в рабочую область",
    "items": {
      "label": "Элементы",
      "data": {
        "label": "Данные",
        "options": {
          "profiles": {
            "label": "Профили",
            "description": "Таблица профилей отображает информацию отдельно по каждому клиенту с возможностью изменения отдельных данных и просмотром истории изменений."
          },
          "segments": {
            "label": "Сегменты",
            "description": "В таблице сегментов можно создавать сегментацию клиентов на основе событий из подключенных источников данных с учетом условий, заданных для атрибутов профилей."
          },
          "exports": {
            "label": "Экспорты",
            "description": "Таблица экспортов позволяет добавлять и просматривать периодические и единоразовые выгрузки в указанные системы."
          }
        }
      },
      "statistics": {
        "label": "Статистика",
        "options": {
          "lineChart": {
            "label": "Линейный график",
            "description": "Описание графика"
          },
          "barChart": {
            "label": "Гистограмма",
            "description": "Описание диаграммы"
          },
          "radarChart": {
            "label": "Лепестковая диаграмма",
            "description": "Описание диаграммы"
          },
          "doughnutChart": {
            "label": "Вложенная круговая диаграмма",
            "description": "Описание диаграммы"
          },
          "bubbleChart": {
            "label": "Пузырьковая диаграмма",
            "description": "Описание диаграммы"
          }
        }
      }
    }
  }
}
</i18n>

<style lang="scss">
.workspace {
  max-height: calc(100vh - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.workspace__header {
  background: $color-layout-light-01;
  padding: 0 $spacing-l $spacing-m $spacing-l;
  width: 100%;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.08);
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
}
.workspace__title {
  margin-bottom: $spacing-xs;
}
.workspace__description {
  margin-bottom: $spacing-2xs;
}
.workspace__header__action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $spacing-xs;
}
.workspace__content {
  max-height: calc(100% - 82px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & > * {
    padding: $spacing-l;
    overflow: auto;
  }
  .workspace__item {
    margin-bottom: $spacing-l;
  }
}
.workspace__item__card {
  box-shadow: rgba(67, 90, 111, 0.1) 0px 0px 1px, rgba(67, 90, 111, 0.2) 0px 2px 4px -2px;
  border: 0;

  .el-card__body {
    padding: 0;
  }
  .el-card__header {
    background-color: $color-layout-light-01;
    padding: 8px 16px 0 16px;
    border: none;
    h6 {
      margin-bottom: 0;
    }
  }
  .data-table__container {
    box-shadow: none;
  }
  .lineChart,
  .barChart,
  .radarChart,
  .doughnutChart,
  .bubbleChart {
    position: relative;
    max-height: 400px;
    padding: 20px;
  }
}
.workspace__item__card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-button {
    padding: 0;
  }
}
.workspace__empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: $spacing-s;
  height: 100%;
}
.workspace__empty__img {
  min-width: 496px;
  min-height: 300px;
  background: url(../../assets/images/workspace.svg) no-repeat no-repeat;
  background-size: contain;
  margin-top: $spacing-2xl;
  margin-bottom: $spacing-s;
}
.workspace__empty__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.duplicate__item-list {
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: $spacing-xs;
  }
}
.workspace__item--managed {
  position: relative;
  cursor: grab;
  animation: shake 0.3s ease-in-out infinite;
  .workspace__item__icon-drag {
    position: absolute;
    display: block;
    content: '';
    top: calc(50% - 24px / 2);
    left: calc(50% - 24px / 2);
    color: $color-layout-dark-01;
    opacity: 0;
    z-index: 1001;
  }
  .workspace__item__icon-close {
    position: absolute;
    display: block;
    content: '';
    top: -16px;
    right: -16px;
    height: 28px;
    padding: 5px;
    background: $color-danger;
    border-radius: 50%;
    color: $color-layout-light-01;
    z-index: 1001;
  }
  &:hover {
    .workspace__item__icon-drag {
      opacity: 0.8;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $color-layout-light-01;
      opacity: 0.6;
      z-index: 1000;
    }
  }
}
.workspace__header__addItem {
  margin-left: 10px;
}

.workspace__items--flip-move {
  transition: transform 0.5s ease-in-out;
}

.workspace__items--flip-enter-active,
.workspace__items--flip-leave-active {
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}

.workspace__items--flip-enter,
.workspace__items--flip-leave-to {
  transform: scale(0.9);
  transition: transform 0.3s ease-in-out;
}

.workspace__item--dragged {
  animation: none;
  cursor: grabbing;
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(0px, 0px) rotate(0.2deg);
  }
  50% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  75% {
    transform: translate(0px, 0px) rotate(-0.2deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
</style>
