<template>
  <el-dialog
    class="segments-builder"
    :visible="visible"
    :title="$t('title')"
    @close="$emit('close')"
    @closed="handleReset"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="100px"
      :show-message="true"
      size="small"
    >
      <div class="segments-buider__container">
        <el-form-item key="conditions" :label="$t('conditions')">
          <div
            v-for="(condition, index) in formData.conditions"
            :key="condition.type + index"
            class="segments-buider__condition"
          >
            <el-form-item
              v-if="condition.type === 'event'"
              :prop="`conditions.${index}.eventId`"
              :rules="formRules[condition.type]"
              :label="$t(`${condition.type}.label`)"
            >
              <div class="segment-builder__event__container">
                <info-select
                  v-model="formData.conditions[index].eventId"
                  class="segments-buider__event__select"
                  :label="activeEvents[condition.eventId] && activeEvents[condition.eventId].name"
                  :placeholder="$t('event.selectEvent')"
                  show-groups
                  :options="eventOptions"
                  @change="handleEventIdChange(index)"
                />
                <el-button
                  size="small"
                  type="text"
                  class="segments-buider__delete"
                  @click="formData.conditions.splice(index, 1)"
                >
                  <icon width="16" height="16" name="trash" />
                </el-button>
              </div>
              <template v-if="activeEvents[condition.eventId]">
                <el-form-item
                  :prop="`conditions.${index}.conditions`"
                  :rules="formRules.eventConditions"
                >
                  <conditions
                    v-model="formData.conditions[index].conditions"
                    :option-placeholder="$t('selectAttribute')"
                  >
                    <template slot="options">
                      <el-option-group
                        v-for="(ids, name) in eventAttributeGroups[condition.eventId]"
                        :key="name"
                        :label="$t(`data.attributeGroups.${name}`)"
                      >
                        <el-option
                          v-for="id in ids"
                          :key="id"
                          :value="id"
                          :label="activeAttributes[id].name"
                        />
                      </el-option-group>
                    </template>
                  </conditions>
                </el-form-item>
                <el-form-item :label="$t('timeRange.label')">
                  <el-select
                    v-model="formData.conditions[index].timeRange.type"
                    class="segment-builder__time__select"
                    @change="handleEventTimeRangeTypeChange(index, $event)"
                  >
                    <el-option value="within" :label="$t('timeRange.types.within')" />
                    <el-option value="between" :label="$t('timeRange.types.between')" />
                  </el-select>
                  <div v-if="formData.conditions[index].timeRange.type === 'between'">
                    <el-input-number
                      v-model="formData.conditions[index].timeRange.value[0]"
                      :min="0"
                      :max="formData.conditions[index].timeRange.value[1] - 1"
                    />
                    <el-input-number
                      v-model="formData.conditions[index].timeRange.value[1]"
                      :min="formData.conditions[index].timeRange.value[0] + 1"
                      :max="31"
                    />
                  </div>
                  <el-input-number
                    v-else
                    v-model="formData.conditions[index].timeRange.value"
                    :min="1"
                    :max="31"
                  />
                </el-form-item>
              </template>
            </el-form-item>
            <el-form-item
              v-if="condition.type === 'attribute'"
              :prop="`conditions.${index}.conditions`"
              :rules="formRules[condition.type]"
              :label="$t(`${condition.type}.label`)"
            >
              <el-button
                size="small"
                type="text"
                class="segments-buider__delete"
                @click="formData.conditions.splice(index, 1)"
              >
                <icon width="16" height="16" name="trash" />
              </el-button>
              <conditions
                v-model="formData.conditions[index].conditions"
                :option-placeholder="$t('selectAttribute')"
              >
                <template slot="options">
                  <el-option-group
                    v-for="(ids, name) in activeAttributeGroups"
                    :key="name"
                    :label="$t(`data.attributeGroups.${name}`)"
                  >
                    <el-option
                      v-for="id in ids"
                      :key="id"
                      :value="id"
                      :label="activeAttributes[id].name"
                    />
                  </el-option-group>
                </template>
              </conditions>
            </el-form-item>
          </div>
          <el-button
            type="default"
            class="el-button_icon--prefix"
            :disabled="addDisabled"
            @click="addEventCondition"
          >
            <icon width="16" height="16" name="add-circle" />
            {{ $t('event.addEvent') }}
          </el-button>
          <el-button
            type="default"
            class="el-button_icon--prefix"
            :disabled="addDisabled"
            @click="addAttributeCondition"
          >
            <icon width="16" height="16" name="add-circle" />
            {{ $t('attribute.addAttribute') }}
          </el-button>
        </el-form-item>
      </div>

      <el-divider />

      <div class="segments-buider__container">
        <div>
          <el-form-item key="name" prop="name" :label="$t('name.label')">
            <el-input v-model="formData.name" />
          </el-form-item>
          <el-form-item key="description" prop="description" :label="$t('description')">
            <el-input v-model="formData.description" type="textarea" />
          </el-form-item>
        </div>
      </div>
    </el-form>
    <dialog-footer
      slot="footer"
      :loading="submitLoading"
      :disabled="submitDisabled"
      :submit-label="$t('actions.submit')"
      @cancel="$emit('close')"
      @submit="handleSubmit"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import InfoSelect from '@/components/InfoSelect'
import Conditions from '@/components/Conditions'
import DialogFooter from '@/components/DialogFooter'

export default {
  name: 'SegmentsBuilder',

  components: {
    InfoSelect,
    Conditions,
    DialogFooter,
  },

  props: {
    visible: Boolean,
  },

  data() {
    return {
      formData: {
        conditions: [],
        name: '',
        description: '',
      },
      formRules: {
        conditions: {
          required: true,
          message: this.$i18n.t('atLeastOneCondition'),
          trigger: 'blur',
        },
        event: {
          required: true,
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$i18n.t('event.required')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        eventConditions: {
          required: true,
          validator: (rule, value, callback) => {
            if (value && value.length && Object.values(value[value.length - 1]).includes('')) {
              callback(new Error(this.$i18n.t('conditionRequired')))
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        attribute: {
          required: true,
          validator: (rule, value, callback) => {
            if (value.length && value[value.length - 1]) {
              if (Object.values(value[value.length - 1]).includes('')) {
                callback(new Error(this.$i18n.t('conditionRequired')))
              }
            } else {
              callback()
            }
          },
          trigger: 'blur',
        },
        name: {
          required: true,
          message: this.$i18n.t('name.required'),
          trigger: 'blur',
        },
      },

      submitDisabled: false,
      submitLoading: false,
    }
  },

  computed: {
    ...mapState('data', ['events', 'attributes', 'activeCloudId']),
    ...mapGetters('data', [
      'activeAttributesList',
      'activeAttributes',
      'activeAttributeGroups',
      'activeEventsList',
      'activeEvents',
    ]),
    addedEvents() {
      return this.formData.conditions
        .filter(condition => condition.type === 'events')
        .map(condition => condition.eventId)
    },
    eventOptions() {
      const groups = []
      const groupEvents = {}
      this.activeEventsList.forEach(event => {
        if (!this.addedEvents.includes(event.id)) {
          if (!groups.includes(event.group)) {
            groups.push(event.group)
            groupEvents[event.group] = []
          }
          groupEvents[event.group].push({
            name: event.id,
            label: event.name,
            description: event.description,
            link: {
              href: '#',
              label: this.$i18n.t('actions.readMore'),
            },
          })
        }
      })
      return groups.map(group => ({
        name: group,
        label: group,
        options: groupEvents[group],
      }))
    },
    eventAttributeGroups() {
      return this.activeEventsList.reduce((groups, event) => {
        groups[event.id] = {}
        event.attributes.forEach(attributeId => {
          const attribute = this.activeAttributes[attributeId]
          if (!groups[event.id][attribute.type]) {
            groups[event.id][attribute.type] = []
          }
          groups[event.id][attribute.type].push(attribute.id)
        })
        return groups
      }, {})
    },
    addDisabled() {
      if (!this.formData.conditions.length) {
        return false
      }
      const lastCondition = this.formData.conditions[this.formData.conditions.length - 1]
      if (lastCondition.type === 'event') {
        const eventConditions = lastCondition.conditions
        return (
          lastCondition.eventId === '' ||
          (eventConditions &&
            eventConditions.length > 0 &&
            Object.values(eventConditions[eventConditions.length - 1]).includes(''))
        )
      } else {
        return lastCondition.conditions.length > 0
          ? Object.values(lastCondition.conditions[lastCondition.conditions.length - 1]).includes(
              ''
            )
          : true
      }
    },
  },

  watch: {
    formData: {
      handler() {
        this.submitDisabled = false
      },
      deep: true,
    },
  },

  methods: {
    handleReset() {
      this.formData = {
        conditions: [],
        name: '',
        description: '',
      }
    },
    handleEventIdChange(index) {
      if (this.formData.conditions[index].conditions) {
        this.formData.conditions[index].conditions = []
      }
    },
    handleEventTimeRangeTypeChange(index, type) {
      this.formData.conditions[index].timeRange.value = type === 'between' ? [0, 2] : 2
    },
    addEventCondition() {
      this.formData.conditions.push({
        type: 'event',
        eventId: '',
        timeRange: { type: 'within', value: 2 },
        conditions: [],
      })
    },
    addAttributeCondition() {
      this.formData.conditions.push({
        type: 'attribute',
        conditions: [{ option: '', operator: '', value: '' }],
      })
    },
    async handleSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.submitLoading = true
            // await
            this.submitLoading = false
          } catch (error) {
            this.submitLoading = false
            this.submitDisabled = true
            return false
          }
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "title": "New segment",
    "conditions": "Conditions",
    "event": {
      "label": "Event",
      "required": "Please select event",
      "selectEvent": "Select event",
      "addEvent": "Add event"
    },
    "conditionRequired": "Please enter condition",
    "atLeastOneCondition": "To build segment add at least one condition",
    "attribute": {
      "label": "Attribute",
      "required": "Please select attribute",
      "addAttribute": "Add attribute"
    },
    "selectAttribute": "Select attribute",
    "name": {
      "label": "Name",
      "required": "Please enter segment name"
    },
    "timeRange": {
      "label": "Time range",
      "required": "Please select time range",
      "types": {
        "within": "within",
        "between": "in between"
      },
      "days": "Days"
    }
  },
  "ru": {
    "title": "Новый сегмент",
    "conditions": "Условия",
    "event": {
      "label": "Событие",
      "required": "Пожалуйста, выберите событие",
      "selectEvent": "Выберите событие",
      "addEvent": "Добавить событие"
    },
    "conditionRequired": "Пожалуйста, введите условие",
    "atLeastOneCondition": "Для создания согмента добавьте хотя бы одно условие",
    "attribute": {
      "label": "Атрибут",
      "required": "Пожалуйста, выберите атрибут",
      "selectAttribute": "Выберите атрибут",
      "addAttribute": "Добавьте атрибут"
    },
    "name": {
      "label": "Название",
      "required": "Пожалуйста, введите название сегмента"
    },
    "timeRange": {
      "label": "Временной интервал",
      "required": "Пожалуйста, выберите временной интервал",
      "types": {
        "within": "в пределах",
        "between": "в промежутке"
      },
      "days": "Дней"
    }
  }
}
</i18n>

<style lang="scss">
.segments-builder {
  .el-select-dropdown__item {
    height: auto;
  }
  .el-divider--horizontal {
    margin-bottom: $spacing-xl;
  }
}
.segments-builder__slider {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: $spacing-m;
  &:last-of-type {
    margin-right: 0;
    flex: 2;
  }
  & > span {
    margin-bottom: $spacing-s;
  }
}
.segments-builder__time .el-form-item__content {
  display: flex;
}
.segments-buider__condition {
  position: relative;
  padding: $spacing-s;
  border: 1px solid $color-layout-light-03;
  background-color: $color-layout-light-02;
  margin-bottom: $spacing-m;
  border-radius: 4px;
}
.segment-builder__event__container {
  margin-bottom: $spacing-s;
  width: auto;
}
.segments-buider__event__select {
  display: block;
  margin-right: $spacing-s;
}
.segment-builder__time__select {
  margin-right: $spacing-s;
}
.segments-buider__delete {
  position: absolute;
  top: -36px;
  right: -10px;
}
</style>
