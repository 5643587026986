var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"profiles-column-settings",attrs:{"visible":_vm.visible,"title":_vm.$t('columnSettings')},on:{"close":function($event){return _vm.$emit('close')},"closed":_vm.setData}},[_c('div',{staticClass:"profiles-column-settings__content"},[_c('div',{staticClass:"profiles-column-settings__group"},[_c('b',{staticClass:"profiles-column-settings__group__title"},[_vm._v(_vm._s(_vm.$t('groups')))]),_c('el-checkbox',{staticClass:"profiles-column-settings__check-all",attrs:{"value":_vm.checkedGroups.length === _vm.groups.length,"indeterminate":_vm.checkedGroups.length > 0 && _vm.checkedGroups.length !== _vm.groups.length},on:{"change":_vm.handleCheckAllGroups}},[_vm._v(" "+_vm._s(_vm.$t('checkAll'))+" ")]),_c('div',{staticClass:"profiles-column-settings__group__list"},[_c('draggable',{attrs:{"animation":200,"drag-class":"profiles-column-settings__group__item__drag"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.groups),callback:function ($$v) {_vm.groups=$$v},expression:"groups"}},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.dragging ? 'flip' : ''}},_vm._l((_vm.groups),function(group,index){return _c('div',{key:group,staticClass:"profiles-column-settings__group__item"},[_c('icon',{class:[
                  'profiles-column-settings__group__item__drag',
                  { 'profiles-column-settings__group__item__drag--active': _vm.dragging },
                ],attrs:{"name":"drag-row"}}),_c('el-checkbox',{key:group,attrs:{"label":group,"value":_vm.checkedGroups.includes(group),"indeterminate":_vm.checkedGroupColumns[index].length > 0 && !_vm.checkedGroups.includes(group)},on:{"change":function($event){return _vm.handleGroupCheckedChange(group, $event)}}},[_c('span',{on:{"click":function($event){$event.preventDefault();_vm.activeGroup = group}}},[_vm._v(_vm._s(_vm.$t(`data.attributeGroups.${group}`)))])]),_c('icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeGroup === group),expression:"activeGroup === group"}],staticClass:"profiles-column-settings__group__item__arrow",attrs:{"name":"arrow-right"}})],1)}),0)],1)],1)],1),_c('div',{staticClass:"profiles-column-settings__group"},[_c('b',{staticClass:"profiles-column-settings__group__title"},[_vm._v(_vm._s(_vm.$t('columns')))]),_c('el-input',{staticClass:"profiles-column-settings__search",attrs:{"placeholder":_vm.$t('searchColumn'),"size":"small"},model:{value:(_vm.groupColumnsQuery),callback:function ($$v) {_vm.groupColumnsQuery=$$v},expression:"groupColumnsQuery"}},[_c('icon',{attrs:{"slot":"prefix","name":"search","width":"16","height":"16"},slot:"prefix"})],1),_c('div',{staticClass:"profiles-column-settings__group__list"},[_c('draggable',{attrs:{"animation":200,"drag-class":"profiles-column-settings__group__item__drag"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.groupColumns[_vm.activeGroup]),callback:function ($$v) {_vm.$set(_vm.groupColumns, _vm.activeGroup, $$v)},expression:"groupColumns[activeGroup]"}},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.dragging ? 'flip' : ''}},_vm._l((_vm.filteredGroupColumns),function(column,index){return _c('div',{key:column.id,staticClass:"profiles-column-settings__group__item"},[_c('icon',{class:[
                  'profiles-column-settings__group__item__drag',
                  { 'profiles-column-settings__group__item__drag--active': _vm.dragging },
                ],attrs:{"name":"drag-row"}}),_c('el-checkbox',{attrs:{"label":column.id},model:{value:(_vm.groupColumns[_vm.activeGroup][index].visible),callback:function ($$v) {_vm.$set(_vm.groupColumns[_vm.activeGroup][index], "visible", $$v)},expression:"groupColumns[activeGroup][index].visible"}},[_vm._v(" "+_vm._s(column.name)+" ")])],1)}),0)],1)],1)],1)]),_c('dialog-footer',{attrs:{"slot":"footer","submit-label":_vm.$t('actions.apply'),"disabled":_vm.submitDisabled},on:{"cancel":function($event){return _vm.$emit('close')},"submit":_vm.handleApply},slot:"footer"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }