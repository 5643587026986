<template>
  <el-drawer
    class="profiles-item"
    :visible="visible"
    size="40%"
    :title="$t('profile')"
    @close="$emit('close')"
    @closed="handleClosed"
  >
    <div class="profiles-item__content">
      <el-card class="profiles-item__card" shadow="never">
        <el-avatar>{{ profileName[0] }}</el-avatar>
        <div class="profiles-item__card__text">
          <span class="profiles-item__card__title">{{ profileName }}</span>
          <el-tag class="profiles-item__card__id" size="small" effect="plain">
            {{ profile.id }}
          </el-tag>
        </div>
      </el-card>
      <div class="profiles-item__menu">
        <el-radio-group v-model="activeGroup" size="small">
          <el-radio-button v-for="group in profileAttributeGroups" :key="group" :label="group">
            {{ $t(`data.attributeGroups.${group}`) }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-table
        ref="table"
        :data="profile[activeGroup]"
        :class="[
          'profiles-item__attributes',
          { 'profiles-item__attributes--selectable': activeGroup === 'user' },
        ]"
        tooltip-effect="dark"
        row-key="id"
        :formatter="formatCell"
        @cell-click="handleShowEdit"
      >
        <el-table-column prop="name" :label="$t('name')" />
        <el-table-column prop="value" :label="$t('value')" />
      </el-table>
    </div>

    <profiles-item-edit
      :visible="showEdit"
      :attribute="activeAttribute"
      :profile-id="profile.id"
      @close="showEdit = false"
    />
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfilesItemEdit from './ProfilesItemEdit'

export default {
  name: 'ProfilesItem',

  components: {
    ProfilesItemEdit,
  },

  props: {
    id: String,
    visible: Boolean,
  },

  data() {
    return {
      activeGroup: 'user',
      activeAttribute: {},
      showEdit: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeProfiles', 'activeWorkspaceAttributes', 'activeAttributeGroups']),
    profileName() {
      const nameField = this.profile.user && this.profile.user.find(field => field.id === 'name')
      return nameField ? nameField.value : this.$i18n.t('incognitoProfile')
    },
    profile() {
      return this.activeProfiles[this.id] || {}
    },
    profileAttributeGroups() {
      return Object.keys(this.profile).filter(field => this.activeAttributeGroups[field])
    },
  },

  methods: {
    handleShowEdit(attribute) {
      if (this.activeGroup !== 'user') {
        return
      }
      this.activeAttribute = attribute
      this.showEdit = true
    },
    handleClosed() {
      this.activeGroup = 'user'
      this.activeAttribute = {}
      this.showEdit = false

      this.$emit('closed')
    },
    formatCell(row, column, value) {
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return value.toLocaleString(this.$i18n.locale)
      }
      return value
    },
  },
}
</script>

<i18n>
{
  "en": {
    "profile": "Profile",
    "profileAttributes": "Profile attributes",
    "computedAttributes": "Computed attributes",
    "incognitoProfile": "Incognito profile",
    "name": "Name",
    "value": "Value"
  },
  "ru": {
    "profile": "Профиль",
    "profileAttributes": "Атрибуты профиля",
    "computedAttributes": "Вычисляемые атрибуты",
    "incognitoProfile": "Профиль инкогнито",
    "name": "Название",
    "value": "Значение"
  }
}
</i18n>

<style lang="scss">
.profiles-item {
  height: auto;
  .el-drawer__header {
    padding: 20px;
    margin-bottom: $spacing-xs;
  }
}
.profiles-item__content {
  padding: 0 $spacing-m;
  overflow: auto;
  max-height: calc(100% - 130px);
}
.profiles-item__attributes {
  margin-bottom: $spacing-m;
  &--selectable {
    .el-table__row {
      cursor: pointer;
    }
  }
}
.profiles-item__card__title {
  font-weight: 600;
  margin-bottom: $spacing-xs;
}
.profiles-item__card {
  margin-bottom: $spacing-l;
  border-color: $color-layout-light-03;
  .el-card__body {
    display: flex;
    align-items: center;
  }
}
.profiles-item__card__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: $spacing-m;
}
.profiles-item__menu {
  margin-bottom: $spacing-m;
}
.profiles-item__card.el-card {
  .el-card__body {
    padding: $spacing-s;
  }
}
</style>
